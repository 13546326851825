import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private _snackBar: MatSnackBar,
    private httpClient: HttpClient,
    private dialog: MatDialog
  ) { }

  snackBarMessage(message: any, action: any) {
    this._snackBar.open(
      message,
      action,
      {
        duration: 2000
      }
    )
  }



  openDialog(componentName: any, height: any, width: any, dataModel: any) {
    return this.dialog.open(componentName, {
      width: width,
      height: height,
      data: dataModel,
      disableClose: true,
    });
  }

}
