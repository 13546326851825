import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path:'',
        redirectTo:'',
        pathMatch:'full'
    },
    {
        path:'',
        loadChildren:()=>import('./layouts/auth/auth.module').then(m=>m.AuthModule)
    },
    {
        path:'admin',
        loadChildren:()=>import('./layouts/admin/admin.module').then(m=>m.AdminModule)
    }
];
